import * as React from 'react';

function SvgWorker(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={40} height={40} ref={svgRef} {...props}>
      <g fill='#2D76CB' fillRule='evenodd'>
        <path d='M15.68 8.999a3.545 3.545 0 101.608-6.906A3.545 3.545 0 0015.68 9' />
        <path
          d='M27.153 6.127l.281.004h.012c.031-.001.123-.003.258-.004h.518c1.232.008 3.428.09 2.972.57-.522.549-.547 2.275-.54 2.836h.093v.895h-2.865v7.311c.068.019.136.039.205.054a1.262 1.262 0 01-.205 2.487v16.891h-.895V20.11c-1.291-.38-2.415-1.03-3.37-1.782-1.07-.846-1.938-1.824-2.63-2.754v20.122a1.949 1.949 0 01-3.897 0V23.36h-.73v12.335a1.949 1.949 0 01-3.897 0v-12.55a1.26 1.26 0 01-1.082-.613C9.68 19.69 9.004 17.463 9 15.63c-.002-1.077.248-2.007.646-2.751a5.193 5.193 0 011.445-1.69c1.038-.785 1.998-.979 2.106-1.006.105-.02.21-.027.312-.022.056-.008.113-.014.171-.014h6.09c.084 0 .166.009.246.025.506-.006.987.293 1.187.79l.01.023.042.1c.04.089.1.225.184.396.166.343.42.83.758 1.383.675 1.108 1.694 2.471 2.982 3.484.557.439 1.16.81 1.808 1.086v-7.006h-3.05v-.895h.094c.006-.56-.019-2.287-.54-2.837-.457-.48 1.837-.56 3.121-.569zm-14.69 7.197c-.222.195-.43.436-.59.74-.2.379-.35.865-.352 1.566-.003.844.238 2.02.942 3.567z' />
      </g>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgWorker);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
