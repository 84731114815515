import * as React from 'react';

function SvgCamera(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={111} height={111} ref={svgRef} {...props}>
      <path
        d='M70.997 60.977c0 8.53-6.953 15.471-15.498 15.471-8.546 0-15.499-6.94-15.499-15.47 0-8.532 6.953-15.472 15.499-15.472 8.545 0 15.498 6.94 15.498 15.471zM101 39.3v43.354c0 4.04-3.311 7.346-7.359 7.346H17.36C13.31 90 10 86.694 10 82.654V39.3c0-4.04 3.311-7.346 7.359-7.346h19.777l2.582-5.968A6.613 6.613 0 0145.791 22h19.415a6.615 6.615 0 016.073 3.986l2.582 5.968h19.78c4.048 0 7.359 3.306 7.359 7.346zm-72.877 1.63c0-.982-.804-1.785-1.788-1.785h-4.927c-.983 0-1.788.803-1.788 1.784v2.411c0 .982.805 1.785 1.788 1.785h4.927c.984 0 1.788-.803 1.788-1.785v-2.41zm50.382 20.047c0-3.099-.608-6.107-1.81-8.94a22.872 22.872 0 00-4.928-7.3 22.933 22.933 0 00-7.312-4.92 22.893 22.893 0 00-8.956-1.806c-3.105 0-6.118.607-8.957 1.806a22.933 22.933 0 00-7.312 4.92 22.891 22.891 0 00-6.739 16.24 22.894 22.894 0 006.74 16.24 22.906 22.906 0 007.311 4.92 22.895 22.895 0 008.957 1.807c3.104 0 6.117-.608 8.956-1.807a22.933 22.933 0 007.312-4.92 22.891 22.891 0 006.739-16.24z'
        fill='#2D76CB'
        fillRule='evenodd'
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCamera);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
