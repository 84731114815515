import * as React from 'react';

function SvgWorkFromHome(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={111} height={111} ref={svgRef} {...props}>
      <g fill='none' fillRule='evenodd'>
        <path
          fill='#2D76CB'
          d='M85.906 87.918c0 .967-.35 4.39-1.047 5.066-.698.677-1.545 1.016-2.542 1.016H29.683c-.997 0-1.844-.339-2.542-1.016-.698-.677-1.047-1.499-1.047-2.466V56.77c0-.58.2-1.064.598-1.45l28.112-22.345c.399-.29.797-.435 1.196-.435.399 0 .797.145 1.196.435L85.308 55.32c.398.387.598.87.598 1.451v31.148zm12.56-39.998c.299.29.473.677.523 1.16.05.484-.074.92-.374 1.306l-3.738 4.498c-.299.387-.698.58-1.196.58s-.947-.145-1.346-.435L57.196 27.027c-.399-.29-.797-.435-1.196-.435-.399 0-.797.145-1.196.435l-35.14 28.002c-.398.29-.847.435-1.345.435s-.897-.193-1.196-.58l-3.738-4.498a1.761 1.761 0 01-.375-1.306c.05-.483.224-.87.523-1.16l37.98-30.324C52.81 16.532 54.305 16 56 16c1.695 0 3.24.532 4.635 1.596l13.308 10.737V17.74c0-.484.175-.895.524-1.233a1.754 1.754 0 011.27-.508h8.374c.499 0 .922.17 1.271.508.35.338.524.75.524 1.233v20.167l12.56 10.012z'
        />
        <path
          fill='#FFF'
          d='M74.241 83.602h-4.854v-9.08c0-6.172-6.112-11.177-13.652-11.177-7.54 0-13.652 5.005-13.652 11.178v9.08H37.23A1.227 1.227 0 0036 84.83c0 .678.55 1.229 1.229 1.229h37.012a1.23 1.23 0 100-2.458M55.735 61.436a7.218 7.218 0 100-14.436 7.218 7.218 0 000 14.436'
        />
        <path
          fill='#2D76CB'
          d='M55.735 79.208a1.345 1.345 0 110-2.69 1.345 1.345 0 010 2.69zm8.058-7.336H47.677c-.824 0-1.438.671-1.364 1.492l.766 8.535c.073.82.808 1.492 1.631 1.492h14.05c.824 0 1.559-.671 1.632-1.492l.766-8.535a1.349 1.349 0 00-1.365-1.492z'
        />
      </g>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgWorkFromHome);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
