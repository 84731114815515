import * as React from 'react';

function SvgHomeOnly(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={90} height={90} ref={svgRef} {...props}>
      <g fill='none' fillRule='evenodd'>
        <path
          fill='#2D76CB'
          d='M36.646 12.058c.821.82.8 2.184-.045 3.03L16.722 34.965c-.845.846-2.208.866-3.03.044-.82-.82-.8-2.182.045-3.029l19.879-19.877c.847-.846 2.21-.866 3.03-.045'
        />
        <path
          fill='#2D76CB'
          d='M33.744 12.058c-.821.82-.8 2.184.045 3.03l19.879 19.877c.846.846 2.21.866 3.03.044.821-.82.8-2.182-.045-3.029L36.775 12.103c-.846-.846-2.21-.866-3.03-.045m15.809 23.148L35.708 21.319a.97.97 0 00-1.368 0L20.493 35.206c-.377.377-.686 1.123-.686 1.655v37.248c0 .534.438.971.971.971H49.27c.534 0 .97-.437.97-.97V36.86c0-.532-.308-1.278-.686-1.655'
        />
        <path
          fill='#FFFFFE'
          d='M39.487 72.115v-11a4.185 4.185 0 00-4.173-4.172h-.58a4.185 4.185 0 00-4.174 4.172v11h8.927M28.879 39.734h4.758v-4.758h-4.758zm7.533 0h4.758v-4.758h-4.758zm-7.533 7.532h4.759v-4.757h-4.759zm7.532 0h4.759v-4.757h-4.759z'
        />
        <path
          fill='#2D76CB'
          d='M76.547 53.133c.14.652-.29 1.185-.957 1.185H54.233a1.215 1.215 0 01-1.211-1.212V40.235c0-.666.545-1.21 1.211-1.21h18.055c.665 0 1.326.532 1.466 1.183l2.793 12.924m-23.525 3.959v16.598c0 .765.626 1.391 1.391 1.391h17.695c.765 0 1.39-.626 1.39-1.39v-16.6H53.023'
        />
        <path fill='#FFFFFE' d='M60.881 68.247h4.758v-7.438h-4.758z' />
      </g>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHomeOnly);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
