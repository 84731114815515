import * as React from 'react';

function SvgEarthQuake(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={111} height={111} ref={svgRef} {...props}>
      <g fill='none' fillRule='evenodd'>
        <path
          fill='#2D76CB'
          d='M98.466 48.92c.299.29.473.677.523 1.16.05.484-.074.92-.374 1.306l-3.738 4.498c-.299.387-.698.58-1.196.58s-.947-.145-1.346-.435L57.196 28.027c-.399-.29-.797-.435-1.196-.435-.399 0-.797.145-1.196.435l-35.14 28.002c-.398.29-.847.435-1.345.435s-.897-.193-1.196-.58l-3.738-4.498a1.761 1.761 0 01-.375-1.306c.05-.483.224-.87.523-1.16l37.98-30.324C52.81 17.532 54.305 17 56 17c1.695 0 3.24.532 4.635 1.596l13.308 10.737V18.74c0-.484.175-.895.524-1.233a1.754 1.754 0 011.27-.508h8.374c.499 0 .922.17 1.271.508.35.338.524.75.524 1.233v20.167l12.56 10.012z'
        />
        <path
          fill='#2D76CB'
          d='M56.036 32.821c.38 0 .761.14 1.141.417L84.005 54.61c.38.37.57.832.57 1.388v31.84L71.46 81.861l10.505-3.786-22.54-10.918 6.886-2.866-26.435-8.994 14.036 8.975-8.378 2.938 16.692 9.945-10.328 4.323 20.382 13.24H30.921c-.951 0-1.76-.323-2.426-.971a3.169 3.169 0 01-.999-2.36v-35.39c0-.555.19-1.017.571-1.387l26.827-21.372c.38-.278.761-.417 1.142-.417z'
        />
        <path fill='#4A90E2' d='M51.787 38.725h-16.58 16.58z' />
      </g>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgEarthQuake);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
