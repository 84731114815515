import * as React from 'react';

function SvgHomeInside(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={90} height={90} ref={svgRef} {...props}>
      <g fill='#2D76CB' fillRule='evenodd'>
        <path
          d='M79.799 49.489a5.182 5.182 0 00-4.756 7.238v2.63h-37.34v-4.19c0-.083-.007-.165-.012-.248.004-.083.012-.164.012-.248a5.182 5.182 0 10-10.364 0 5.177 5.177 0 002.84 4.618v8.656a3.762 3.762 0 005.194 3.478h42a3.762 3.762 0 005.195-3.478v-8.9a5.172 5.172 0 002.413-4.375 5.182 5.182 0 00-5.182-5.182' />
        <path
          d='M39.975 54.813a6.85 6.85 0 01-.428 2.37c.21.025.424.043.64.043h31.945c.452 0 .887-.064 1.303-.173a6.866 6.866 0 01-.38-2.24 6.888 6.888 0 014.189-6.337v-.62a5.112 5.112 0 00-5.112-5.111H40.189a5.11 5.11 0 00-5.111 5.111v.365a6.887 6.887 0 014.898 6.592M63.747 41.025c0-6.432-.005-5.396-.005-11.826h-.159c-.476-.161-.796-.46-.796-.803 0-.052.007-.103.021-.152h3.172c.014.05.022.1.022.152 0 .343-.32.642-.797.803h-.146c0 6.43-.006 5.394-.006 11.826h-1.306zm-7.384-13.871a9.576 9.576 0 01-.11-1.458c0-3.282 1.622-6.15 4.036-7.696h8.212c2.414 1.546 4.035 4.414 4.035 7.696 0 .497-.037.983-.109 1.458H56.362z' />
        <g>
          <path
            d='M8.155 62.309H21.67v-1.295H8.155zM9.028 36.65c-.72 0-1.286.456-1.226.988L9.35 51.359c.053.473.586.836 1.225.836h4.985V36.65H9.028' />
          <path
            d='M20.215 51.36c-.054.472-.586.835-1.226.835h-8.865V36.65h10.412c.72 0 1.287.456 1.226.988l-1.547 13.721zM6.762 58.076L5.478 71.635h1.284l2.727-13.559H6.762m15.63 0l1.283 13.559h-1.283l-2.728-13.559h2.728m1.762-1.792H5c1.398-1.128 5.152-1.937 9.578-1.937 4.425 0 8.179.81 9.576 1.937z' />
          <path d='M5 58.856h19.154v-2.572l-2.299-.76H7.038L5 56.284z' />
        </g>
      </g>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHomeInside);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
