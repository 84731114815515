import * as React from 'react';

function SvgFolder(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={34} height={27} ref={svgRef} {...props}>
      <g fill='none' fillRule='evenodd'>
        <path
          fill='#2B70C2'
          stroke='#2D76CB'
          strokeWidth='.5'
          d='M2.223 25.383C.997 25.383 0 24.284 0 22.933V2.45C0 1.1.997 0 2.223 0H15.56c1.655 0 2.548.944 3.255 1.828.548.687 1.704 2.112 2.14 2.649h4.32c1.225 0 2.222 1.099 2.222 2.45l-.001 2.1c-.02.481-.269.653-.572.653-.298 0-.543-.12-.57-.652-.002-.034-.003-2.082-.003-2.102 0-.681-.483-1.236-1.077-1.236h-4.582c-.167 0-.326-.077-.435-.21-.016-.02-1.634-2.013-2.317-2.868-.725-.907-1.32-1.399-2.38-1.399H2.223c-.594 0-1.077.555-1.077 1.237v20.483c0 .682.483 1.237 1.077 1.237h23.051c.317 0 .574.271.574.606 0 .335-.257.607-.574.607H2.223z'
          transform='translate(-172 -813) translate(30 194) translate(26 572) translate(0 25) translate(98.628 22.78) translate(17.77)'
        />
        <path
          fill='#2B70C2'
          stroke='#2D76CB'
          strokeWidth='.5'
          d='M1.154 23.33c.062.472.518.84 1.069.84h23.07c.517 0 .887-.651 1.354-1.57.116-.23.236-.466.36-.68.398-.682 2.873-6.863 4.474-10.935-.059-.475-.516-.845-1.07-.845l-23.048.13c-.441 0-.553.142-.983.937l-.176.322c-.413.74-3.731 8.64-5.05 11.801zm24.139 2.053H2.223C.997 25.383 0 24.413 0 23.22c0-.084.016-.168.049-.245.189-.453 4.628-11.097 5.167-12.063.06-.107.116-.21.169-.308.43-.795.837-1.547 1.975-1.547l23.048-.13c1.23 0 2.226.97 2.226 2.163 0 .08-.015.159-.044.233-.41 1.045-4.028 10.241-4.607 11.234-.106.183-.213.394-.326.617-.5.984-1.122 2.209-2.364 2.209h0z'
          transform='translate(-172 -813) translate(30 194) translate(26 572) translate(0 25) translate(98.628 22.78) translate(17.77)'
        />
      </g>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFolder);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
