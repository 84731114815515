import * as React from 'react';

function SvgThirdSide(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={40} height={40} ref={svgRef} {...props}>
      <g fill='#2D76CB' fillRule='evenodd'>
        <path
          d='M29.06 5.217a3.197 3.197 0 00-3.196 3.197c0 .935.416 1.758 1.058 2.34.42.18.68.607.646 1.063l-.79 10.706.652 8.818c.05.67.608 1.189 1.28 1.189h.701c.672 0 1.23-.519 1.28-1.19l.655-8.839.184-.078a3.21 3.21 0 001.954-2.72l.357-4.836a3.21 3.21 0 00-1.94-3.188l-1.221-.522a3.185 3.185 0 001.578-2.743 3.197 3.197 0 00-3.197-3.197zM9.949 5.217a3.198 3.198 0 013.197 3.197c0 .935-.416 1.758-1.058 2.34-.42.18-.68.607-.647 1.063l.791 10.706-.652 8.818a1.284 1.284 0 01-1.28 1.189h-.701a1.283 1.283 0 01-1.28-1.19l-.655-8.839-.185-.078a3.21 3.21 0 01-1.953-2.72l-.357-4.836a3.204 3.204 0 011.939-3.187l1.222-.522a3.185 3.185 0 01-1.578-2.743 3.197 3.197 0 013.197-3.197zM19.549 3a3.997 3.997 0 00-3.997 3.996c0 1.465.797 2.733 1.973 3.428l-2.055.879a3.211 3.211 0 00-1.939 3.188l.525 7.105a3.21 3.21 0 001.953 2.721l.683.288.84 11.347c.05.67.608 1.189 1.28 1.189h1.473c.672 0 1.23-.519 1.28-1.19l.84-11.346.683-.288a3.21 3.21 0 001.953-2.721l.525-7.105a3.208 3.208 0 00-1.94-3.188l-2.054-.879a3.978 3.978 0 001.973-3.428A3.997 3.997 0 0019.549 3z' />
      </g>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgThirdSide);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
