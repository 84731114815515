import * as React from 'react';

function SvgHouseBroken(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={40} height={40} ref={svgRef} {...props}>
      <path
        d='M20 10.514c.158 0 .315.057.473.172l11.114 8.8a.763.763 0 01.236.571v13.548c0 .38-.138 1.728-.414 1.995-.276.267-.61.4-1.005.4H9.596a1.39 1.39 0 01-1.005-.4 1.3 1.3 0 01-.414-.971V20.057c0-.228.079-.419.236-.571l11.114-8.8a.799.799 0 01.473-.172zm4.778 15.746a.161.161 0 00-.124.056.229.229 0 00-.018.029l-1.81 2.9-.086.135a1.836 1.836 0 00-.248.917c0 1.117 1.024 2.023 2.286 2.023 1.263 0 2.287-.906 2.287-2.023 0-.385-.122-.745-.333-1.051l-1.811-2.9-.008-.016-.01-.014a.16.16 0 00-.125-.056zm0-8.466a.162.162 0 00-.124.057.167.167 0 00-.018.028l-1.81 2.901-.086.135a1.834 1.834 0 00-.248.917c0 1.117 1.024 2.022 2.286 2.022 1.263 0 2.287-.905 2.287-2.022 0-.385-.122-.745-.333-1.052l-1.811-2.9-.008-.015-.01-.014a.161.161 0 00-.125-.057zM31.114 4c.197 0 .364.067.502.2s.207.295.207.486v7.943l4.966 3.942a.72.72 0 01.207.458c.02.19-.03.361-.148.514l-1.478 1.771a.567.567 0 01-.473.229.881.881 0 01-.532-.172L20.473 8.343A.806.806 0 0020 8.171a.806.806 0 00-.473.172L5.635 19.37c-.158.115-.335.172-.532.172s-.355-.076-.473-.229l-1.478-1.771a.699.699 0 01-.148-.514.722.722 0 01.207-.458L18.227 4.63A2.71 2.71 0 0120 4c.67 0 1.28.21 1.833.629l5.26 4.228V4.686c0-.19.07-.353.208-.486a.697.697 0 01.502-.2h3.31z'
        fill='#2D76CB'
        fillRule='evenodd'
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHouseBroken);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
