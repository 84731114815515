import * as React from 'react';

function SvgBikes(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={111} height={111} ref={svgRef} {...props}>
      <path
        d='M15.989 76.022c-6.652-6.635-6.652-17.416 0-24.054 4.717-4.708 11.529-6.077 17.461-4.102l-.01.016 1.004-2.086 4.015-11.624h-8.675c-1.509 0-2.423 1.005-2.423 1.892 0 .886.914 1.891 2.423 1.891h3.097v4.172H29.34c-3.43 0-6.294-2.673-6.294-6.063 0-3.391 2.864-6.064 6.294-6.064h11.462c1.914.284 2.285 1.477 2.042 3.033l-1.991 6.409h23.681l.278-1.487h-2.688c-1.013 0-1.834-.746-1.834-1.665 0-.922.821-1.665 1.834-1.665h7.595c1.013 0 1.835.743 1.835 1.665 0 .919-.822 1.665-1.835 1.665H68.21l-.751 4.02 6.316 7.703-.006-.01c6.597-4.22 15.48-3.451 21.242 2.3 6.652 6.638 6.652 17.416 0 24.054-6.648 6.637-17.452 6.637-24.103 0a16.905 16.905 0 01-4.382-7.522l-7.275.597-21.642-21.26-.774 1.61.01-.018a17.186 17.186 0 013.247 2.539c6.652 6.638 6.652 17.419 0 24.054-6.651 6.637-17.452 6.637-24.103 0zm20.875-3.219c4.871-4.861 4.871-12.752 0-17.614a12.628 12.628 0 00-2.01-1.63l-5.345 10.97c-1.502 2.928-4.791 1.136-3.525-1.356l5.468-11.157c-4.21-1.187-8.924-.131-12.235 3.173-4.871 4.862-4.871 12.753 0 17.614 4.871 4.858 12.776 4.858 17.647 0zm54.922-.003c4.868-4.858 4.868-12.75 0-17.61-4.091-4.08-10.324-4.737-15.1-1.962l6.835 8.331c1.6 1.008 2.784 2.616 2.26 4.08-.502 1.384-2.165 1.885-4.124 1.62l-10.43.855-.044.003a12.386 12.386 0 002.953 4.683c4.871 4.861 12.78 4.861 17.65 0zM63.4 63.776l2.525-.16a16.929 16.929 0 014.778-11.438l.007.01-3.89-5.518-3.42 17.106zm-3.698-.261l3.416-19.4-22.914-.096 19.498 19.496zm10.848-.198l10.254.57-7.41-7.89a12.39 12.39 0 00-2.892 7.323l.048-.003z'
        fill='#2D76CB'
        fillRule='evenodd'
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBikes);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
